import { DatePicker, Table, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useQueryParam, NumberParam } from "use-query-params";
import { Tabs } from "antd";
import { useParams } from "react-router";
import { positionToOrgMapping } from "utils";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDaiyPayWithParams } from "store/loans";
import { getDailySavingsWithParams } from "store/loans";
import { getOrgDashboardIdField } from "utils";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { getSummationOfFields } from "utils";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";

const { TabPane } = Tabs;
const filters = [
  { value: "paid", label: "paid" },
  { value: "unpaid", label: "unpaid" },
  { value: "waived", label: "waived" },
  { value: "paid_with_no_approval_date", label: "paid without approval date" },
];

const DashboardDailypayList = () => {
  // Defaults to 3 days from now
  const [dateRange, setDateRage] = useState([
    moment().subtract(0, "days"),
    moment(),
  ]);
  const [approvedDate, setApprovedDate] = useState();
  const {
    users: { user },
    loans: { loadingDailyPay, dailyPay, loadingSavings, dailySavings },
  } = useSelector((state) => ({
    users: state.users,
    loans: state.loans,
  }));
  const dispatch = useDispatch();
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [filteredDailyPay, setFilteredDailyPay] = useState([]);
  const [filteredSavings, setFilteredSavings] = useState([]);
  const [filterClient, setFilterClient] = useState([]);
  const [filterPaymentPlan, setFilterPaymentPlan] = useState("");
  const [filterName, setFilterName] = useState("");
  const [paid, setPaid] = useState(undefined);
  const [waived, setWaived] = useState(undefined);
  const [paidWithNullApprovalDate, setPaidWithNullApprovalDate] =
    useState(undefined);
  const [activeTab, setActiveTab] = useState("installments");
  const [activeFilter, setActiveFilter] = useState(undefined);

  const { fullListName } = useListTitle("daily payments");

  const [summedData, setSummedData] = useState({});
  const { renderFilter } = useFilterDateDropdown({
    setFilterDate: setDateRage,
  });
  const { type, id } = useParams();
  const orgType = type || positionToOrgMapping[user.position];
  const orgId = id ? id : orgType === "cos" ? user.id : user[orgType]?.id;

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: activeTab === "installments" ? filteredDailyPay : filteredSavings,
        fieldsToSum: ["amount"],
        fieldWithHandlers: {
          total: (rowInstance) => getTotal(rowInstance),
          cashInHand: (rowInstance) => getCashInHand(rowInstance),
        },
      })
    );
  }, [activeTab, filteredDailyPay, filteredSavings]);

  const getData = async () => {
    let queryString = "";

    const field_id = getOrgDashboardIdField(orgType);
    queryString = queryString.concat(
      `${field_id}=${orgId}&client_id=${filterClient}&start_date=${dateRange?.[0]?.format(
        "YYYY/MM/DD"
      )}&end_date=${dateRange?.[1]?.format(
        "YYYY/MM/DD"
       )}&paid=${paid}&waived=${waived}&repayment_plan=${filterPaymentPlan}&paid_with_null_approval_date=${paidWithNullApprovalDate}&approval_date=${approvedDate ? approvedDate?.format("YYYY/MM/DD") : null}`
    );
    await dispatch(getDaiyPayWithParams(queryString, orgId, orgType));
    await dispatch(getDailySavingsWithParams(queryString, orgId, orgType));
  };

  const handleDateRageChange = (val) => setDateRage(val);

  const handleFilterChange = (val) => {
    if (val === "paid") {
      setPaid(true);
      setWaived(undefined);
      setPaidWithNullApprovalDate(undefined);
      return;
    }
    if (val === "paid_with_no_approval_date") {
      setPaidWithNullApprovalDate(true);
      setWaived(undefined);
      setPaid(undefined);
      return;
    }
    if (val === "waived") {
      setPaid(undefined);
      setWaived(true);
      setPaidWithNullApprovalDate(undefined);
      return;
    }
    if (val === "unpaid") {
      setPaid(false);
      setWaived(undefined);
      setPaidWithNullApprovalDate(undefined);
      return;
    }
    setActiveFilter(val);
  };

  const renderStatus = (paid, waived) => {
    return waived ? (
      <div className="status-badge badge badge-sm">Waived</div>
    ) : (
      <div
        className={classNames(
          "status-badge badge badge-sm",
          paid ? "badge-success" : "badge-warning"
        )}
      >
        {paid ? "Paid" : "Unpaid"}
      </div>
    );
  };

  const handleFilter = () => {
    setFilteredDailyPay(() =>
      dailyPay?.[orgType]?.[id]?.filter((val) =>
        val?.client_name?.toLowerCase()?.includes(filterName?.toLowerCase())
      )
    );

    setFilteredSavings(() =>
      dailySavings?.[orgType]?.[id]?.filter((val) =>
        val?.client_name?.toLowerCase()?.includes(filterName?.toLowerCase())
      )
    );
  };

  useEffect(() => {
    handleFilter();
  }, [filterName]);

  useEffect(() => {
    setFilteredDailyPay(dailyPay?.[orgType]?.[orgId]);
  }, [dailyPay]);

  useEffect(() => {
    setFilteredSavings(dailySavings?.[orgType]?.[orgId]);
  }, [dailySavings]);

  useEffect(() => {
    getData();
  }, [paid, dateRange, waived, filterPaymentPlan, paidWithNullApprovalDate, approvedDate]);

  const tableColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "Date",
      render: (text, record) => <>{record?.date}</>,
      summary: "-",
    },
    {
      title: "Amount ₦",
      dataIndex: "amount",
      render: (text, record) => (
        <>{Number(record?.amount || 0).toLocaleString()}</>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Status",
      dataIndex: "paid",
      render: (text, record) => renderStatus(record?.paid, record?.waived),
      summary: "-",
    },
    {
      title: "Payment Plan",
      dataIndex: "repayment_plan",
      summary: "-",
    },
    {
      title: "Approval Date",
      dataIndex: "approval_date",
      summary: "-",
    },
    {
      title: "Loan",
      dataIndex: "loan",
      render: (text, record) => (
        <Link to={`/loans/${record.loan_id}`}>
          <i className="fa fa-link" aria-hidden="true"></i> By:{" "}
          {`${record?.client_name}`}
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Credit Officer",
      dataIndex: "co_name",
      summary: "-",
    },
  ];

  const renderTableData = (tab) => {
    return (
      <Row>
        <Col>
          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={tableColumns}
            dataSource={
              tab === "installments" ? filteredDailyPay : filteredSavings
            }
            bordered
            pagination={{
              defaultCurrent: page,
              current: page,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setPage(current),
            }}
            showSummary={true}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className="daily-pay-list">
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "30%" }}
          placeholder="Filter by status"
          value={activeFilter}
          onChange={handleFilterChange}
        >
          {filters?.map(({ label, value }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "30%" }}
          placeholder="Filter by repayment plan"
          value={filterPaymentPlan}
          onChange={(val) => setFilterPaymentPlan(val)}
        >
          <Select.Option key="daily" value="daily">
            Daily
          </Select.Option>
          <Select.Option key="weekly" value="weekly">
            Weekly
          </Select.Option>
        </Select>
        <Input
          style={{ width: "40%" }}
          placeholder="Filter by client's firstname or surname"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      <Input.Group compact className="mb-3 filter-panel">
        {renderFilter()}
        <DatePicker.RangePicker
          allowClear={false}
          style={{ width: "60%" }}
          value={dateRange}
          onChange={handleDateRageChange}
          className="mb-3"
        />

        <DatePicker
          allowClear={true}
          style={{ width: "35%" }}
          value={approvedDate}
          onChange={setApprovedDate}
          className="mb-3"
          placeholder="Approval Date"
        />
      </Input.Group>

      {(loadingDailyPay || loadingSavings) && (
        <div className="d-flex w-100 justify-content-center align-items-center my-2">
          <i class="fas fa-spinner fa-spin "></i>{" "}
          <span className="ml-2">Loading...</span>
        </div>
      )}
      <Tabs
        defaultActiveKey="1"
        onChange={(active) => setActiveTab(active)}
        className=""
      >
        <TabPane tab="Installments" key="installments">
          {renderTableData("installments")}
        </TabPane>
        <TabPane tab="savings" key="savings">
          {renderTableData("savings")}
        </TabPane>
      </Tabs>
    </Container>
  );
};

export { DashboardDailypayList };
